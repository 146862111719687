import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  Stack,
  TableCell,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import UploadIcon from "@mui/icons-material/Upload";
import AddIcon from "@mui/icons-material/Add";

import {
  TablePagination,
  TableWrapper,
  StyledTableRow,
  StyledTableCell,
  ConfirmationModal,
  ExportBtn,
} from "../../../component";
import usePageTitle from "../../../hooks/use-page-title";
import ApiManager from "../../../services/api-manager";
import { handleLoader, setToast } from "../../../store/reducer";
import moment from "moment";
import calculateTime from "../../../hooks/calculate-time-diff";

function SpMerchandise() {
  usePageTitle("Merchandise");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const [modalState, setModalState] = useState(false);
  const [recordID, setRecordID] = useState(null);
  const [columns, setColumns] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    getData();
  }, [page, rowsPerPage]);

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `superAdmin/merchandise?page=${page}&per_page=${rowsPerPage}`,
      );
      setRecords(data?.merchandiseProducts);
      setColumns(data?.columns);

      let timeDiff = calculateTime(
        data?.merchandiseProducts?.data[1]?.created_at,
      );
      // let timeDiff = calculateTime(null)
      console.log("🚀 ~ getData ~ timeDiff:", timeDiff);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openModal = (_id) => {
    setModalState(true);
    setRecordID(_id);
  };

  const closeModal = () => {
    setModalState(false);
    setRecordID(null);
  };

  const deleteRecord = async () => {
    try {
      dispatch(handleLoader(true));
      await ApiManager("delete", `superAdmin/merchandise/${recordID}`);
      showMessage("success", "Merchandise deleted successfully.");
      closeModal();
      getData();
    } catch (error) {
      showMessage("error", error?.response?.data?.error?.message);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const renderRow = (item) => (
    <StyledTableRow key={item?.product_id}>
      <StyledTableCell>{item?.product_id}</StyledTableCell>
      <StyledTableCell>
        <img
          src={item?.image_url}
          width="45px"
          height="45px"
          alt="product.png"
        />
      </StyledTableCell>
      <StyledTableCell>{item?.name}</StyledTableCell>
      <StyledTableCell>{item?.sku}</StyledTableCell>
      <StyledTableCell>${Number(item?.price).toLocaleString()}</StyledTableCell>
      <StyledTableCell>${item?.product_tax}</StyledTableCell>
      <StyledTableCell>{item?.quantity}</StyledTableCell>
      <StyledTableCell>{item?.status ? "Active" : "Disabled"}</StyledTableCell>
      <StyledTableCell>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Tooltip title="Edit" placement="top">
            <IconButton
              color="info"
              onClick={() =>
                navigate(`/admin/merchandise/update/${item?.product_id}`)
              }
            >
              <CreateRoundedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="top">
            <IconButton
              color="error"
              onClick={() => openModal(item?.product_id)}
            >
              <DeleteForeverIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </StyledTableCell>
    </StyledTableRow>
  );

  const handleCsvUpload = async (event) => {
    if (!event.target.files.length) {
      return;
    }
    let ext = event.target.files[0].name.split(".");
    if (ext[1] !== "csv") {
      return dispatch(
        setToast({
          type: "error",
          message: "Please select only .csv extension file.",
        }),
      );
    }
    try {
      dispatch(handleLoader(true));
      let _fd = new FormData();
      _fd.append("file", event.target.files[0]);
      let { data } = await ApiManager("post", "superAdmin/mp/upload-csv", _fd, {
        "Content-Type": "multipart/form-data",
      });
      showMessage("success", data);
      if (page === 1) {
        getData();
      } else {
        setPage(1);
      }
    } catch (error) {
      showMessage("error", error?.response?.data?.error?.message);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
            Merchandise
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack direction={{ sm: "row", xs: "column" }} my={2}>
            <Button
              onClick={() => navigate("/admin/merchandise/create")}
              startIcon={<AddIcon />}
            >
              Add Merchandise
            </Button>

            <Button
              startIcon={<UploadIcon />}
              onClick={() => document.getElementById("myFileInput").click()}
            >
              File Upload
            </Button>
            <input
              type="file"
              id="myFileInput"
              accept=".csv"
              style={{
                display: "none",
              }}
              onChange={handleCsvUpload}
            />
            <ExportBtn
              path={`reports-admin-merchandise-products-csv?page=${page}&per_page=${rowsPerPage}&`}
              data={columns}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <TableWrapper
            pagination={
              <TablePagination
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                page={page}
                rowsPerPage={rowsPerPage}
                perPage={records?.per_page || 0}
                total={records?.total || 0}
              />
            }
            spanTd={thLabels.length}
            isLoading={isLoading}
            isContent={records?.data?.length || false}
            thContent={
              <>
                {thLabels.map((v) => (
                  <TableCell key={v}>{v}</TableCell>
                ))}
              </>
            }
          >
            {records?.data.map((item, _) => renderRow(item))}
          </TableWrapper>
        </Grid>
      </Grid>

      <ConfirmationModal
        callBack={deleteRecord}
        open={modalState}
        handleClose={closeModal}
      />
    </>
  );
}

export default SpMerchandise;

const thLabels = [
  "Product ID",
  "Image",
  "Name",
  "SKU",
  "Price",
  "Tax",
  "Quantity",
  "Status",
  "Manage",
];
