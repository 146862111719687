import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  TableCell,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import { Settings } from "@mui/icons-material";
import UploadIcon from "@mui/icons-material/Upload";
import { useDispatch, useSelector } from "react-redux";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import EmailIcon from "@mui/icons-material/Email";

import {
  ExportBtn,
  StyledTableCell,
  StyledTableRow,
  TablePagination,
  TableWrapper,
} from "../../../../component";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";
import { handleLoader, setToast } from "../../../../store/reducer";
import Utils from "../../../../utils/utils";

const Vendor = () => {
  usePageTitle("Vendors");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loggedEvent, user } = useSelector((state) => state.storeReducer);
  const _isAdmin = user?.role === "super_admin" ? true : false;
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";
  const { id } = useParams();
  const _symbol = loggedEvent?.currencySymbol;
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    getData();
  }, [page, rowsPerPage, id]);

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `${_role}/vendors?event_id=${id}&page=${page}&per_page=${rowsPerPage}`,
      );
      setRecords(data?.vendors);
      setColumns(data?.columns);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatus = async (e, item) => {
    let updateRecord = records?.data;
    let _index = updateRecord?.findIndex(
      (x) => x?.vendor_id === item?.vendor_id,
    );
    if (_index >= 0) {
      updateRecord[_index].status = e.target.checked;
      setRecords({ ...records, data: updateRecord });
    }
    let _fd = {
      ...item,
      status: e.target.checked === true ? "true" : "false",
      event: id,
      _method: "patch",
    };
    try {
      dispatch(handleLoader(true));
      await ApiManager("post", `organizer/vendors/${item?.vendor_id}`, _fd);
    } catch (error) {
      dispatch(
        setToast({
          type: "error",
          message: error?.response?.data?.error?.message,
        }),
      );
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const handleCsvUpload = async (event) => {
    if (!event.target.files.length) {
      return;
    }
    let ext = event.target.files[0].name.split(".");
    if (ext[1] !== "csv") {
      return dispatch(
        setToast({
          type: "error",
          message: "Please select only .csv extension file.",
        }),
      );
    }
    try {
      dispatch(handleLoader(true));
      let _fd = new FormData();
      _fd.append("file", event.target.files[0]);
      let { data } = await ApiManager(
        "post",
        `organizer/vendor/upload-csv?event_id=${id}`,
        _fd,
        { "Content-Type": "multipart/form-data" },
      );
      dispatch(setToast({ type: "success", message: data }));
      if (page === 1) {
        getData();
      } else {
        setPage(1);
      }
    } catch (error) {
      dispatch(
        setToast({
          type: "error",
          message: error?.response?.data?.error?.message,
        }),
      );
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const sendEmail = async (id) => {
    try {
      dispatch(handleLoader(true));
      await ApiManager("get", `organizer/send-follow-up-email/${id}`);
      dispatch(setToast({ type: "success", message: "Email has been send." }));
    } catch (error) {
      dispatch(
        setToast({
          type: "error",
          message:
            error?.response?.data?.error?.message ||
            error?.response?.data?.error?.message,
        }),
      );
    } finally {
      dispatch(handleLoader(false));
    }
  };

  return (
    <div>
      <Box>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          Vendors
        </Typography>

        <Stack direction={{ sm: "row", xs: "column" }} my={2}>
          {!_isAdmin && (
            <>
              <Button
                onClick={() =>
                  navigate(`/event/${id}/management/cashless/vendors/create`)
                }
                startIcon={<AddIcon />}
              >
                Add Vendor
              </Button>

              <Button
                startIcon={<UploadIcon />}
                onClick={() => document.getElementById("myFileInput").click()}
              >
                File Upload
              </Button>
              <input
                type="file"
                id="myFileInput"
                accept=".csv"
                style={{
                  display: "none",
                }}
                onChange={handleCsvUpload}
              />
            </>
          )}
          <ExportBtn
            path={`reports-event-vendors-csv?event_id=${id}&page=${page}&per_page=${rowsPerPage}&`}
            data={columns}
          />
        </Stack>

        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.per_page || 0}
              total={records?.total || 0}
            />
          }
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.data?.length || false}
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {records?.data?.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>{item?.name}</StyledTableCell>
              <StyledTableCell>{item?.email}</StyledTableCell>
              <StyledTableCell>{item?.total_sku}</StyledTableCell>
              <StyledTableCell>{item?.tax_number}</StyledTableCell>
              <StyledTableCell>
                {item?.allow_tip ? "Yes" : "No"}
              </StyledTableCell>
              <StyledTableCell>
                {_symbol}
                {item?.sales ? Utils.formatWithSuffix(item?.sales) : 0}
              </StyledTableCell>
              <StyledTableCell>
                {_symbol}
                {item?.expense ? Utils.formatWithSuffix(item?.expense) : 0}
              </StyledTableCell>
              <StyledTableCell>
                <FormControlLabel
                  control={
                    <Switch
                      checked={item?.status}
                      disabled={_isAdmin}
                      onChange={(e) => handleStatus(e, item)}
                    />
                  }
                />
              </StyledTableCell>
              <StyledTableCell>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  {!_isAdmin && (
                    <>
                      <Tooltip title="Edit" placement="top">
                        <IconButton
                          color="info"
                          onClick={() =>
                            navigate(
                              `/event/${id}/management/cashless/vendors/update/${item?.vendor_id}`,
                            )
                          }
                        >
                          <CreateRoundedIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Send follow up email" placement="top">
                        <IconButton
                          color="info"
                          onClick={() => sendEmail(item?.vendor_id)}
                        >
                          <EmailIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  <Tooltip title="Manage" placement="top">
                    <IconButton
                      type="button"
                      color="info"
                      onClick={() =>
                        navigate(
                          `/event/${id}/management/cashless/vendors/manage/${item?.vendor_id}/product`,
                        )
                      }
                    >
                      <Settings />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Logs" placement="top">
                    <IconButton
                      type="button"
                      color="info"
                      onClick={() =>
                        navigate(
                          `/event/${id}/management/cashless/vendors/${item?.vendor_id}/logs`,
                        )
                      }
                    >
                      <TextSnippetIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableWrapper>
      </Box>
    </div>
  );
};

const thLabels = [
  "Name",
  "Email",
  "Total SKU,s",
  "Tax Number",
  "Allow Tip",
  "Total Sales",
  "Total Expense",
  "Status",
  "Manage",
];

export default Vendor;
