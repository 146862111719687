import React, { Fragment, useEffect, useState } from "react";
import { Box, Typography, Button, Stack, TableCell } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

import {
  ExportBtn,
  StyledTableCell,
  StyledTableRow,
  TablePagination,
  TableWrapper,
} from "../../../component";
import usePageTitle from "../../../hooks/use-page-title";
import ApiManager from "../../../services/api-manager";
import Utils from "../../../utils/utils";

const dateFormat = process.env.REACT_APP_DATE_FORMAT;

const Attendee = () => {
  usePageTitle("Attendee");
  const { id } = useParams();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const [columns, setColumns] = useState([]);
  const { user } = useSelector((state) => state.storeReducer);
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";

  useEffect(() => {
    getData();
  }, [page, rowsPerPage]);

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `${_role}/attendees/${id}?&page=${page}&per_page=${rowsPerPage}`,
      );
      setRecords(data?.attendees);
      setColumns(data?.columns);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderRow = (item) => (
    <StyledTableRow>
      <StyledTableCell>{item?.ticket_id || "-"}</StyledTableCell>
      <StyledTableCell>{item?.ticket_type?.name || "-"}</StyledTableCell>
      <StyledTableCell>{item?.name || "-"}</StyledTableCell>
      <StyledTableCell>{item?.email || "-"}</StyledTableCell>
      <StyledTableCell>{item?.open_id || "-"}</StyledTableCell>
    </StyledTableRow>
  );
  const thLabels = [
    "Ticket ID",
    "Ticket Type",
    "Name On Ticket",
    "Email",
    "Open ID",
  ];

  return (
    <div>
      <Box>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          Attendee
        </Typography>
        <Stack direction="row" m={2}>
          <ExportBtn
            data={columns}
            path={`reports-event-attendees-csv?event_id=${id}&page=${page}&per_page=${rowsPerPage}`}
          />
        </Stack>

        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.per_page || 0}
              total={records?.total || 0}
            />
          }
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.data?.length || false}
        >
          {records?.data.map((item, index) => (
            <Fragment key={index} children={renderRow(item)} />
          ))}
        </TableWrapper>
      </Box>
    </div>
  );
};

export default Attendee;
