import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  TableCell,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import { useDispatch } from "react-redux";

import {
  StyledTableCell,
  StyledTableRow,
  TableWrapper,
  TablePagination,
  ExportBtn,
} from "../../../component";
import { handleLoader, setToast } from "../../../store/reducer";
import ApiManager from "../../../services/api-manager";
import usePageTitle from "../../../hooks/use-page-title";

const SpVenue = () => {
  usePageTitle("Venue");
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [columns, setColumns] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    getData();
  }, [page, rowsPerPage]);

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `superAdmin/venues?page=${page}&per_page=${rowsPerPage}`,
      );
      setRecords(data?.venues);
      setColumns(data?.columns);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatus = async (e, item, index) => {
    let updateRecord = records?.data;
    let _index = updateRecord?.findIndex((x) => x?.venue_id === item?.venue_id);
    if (_index >= 0) {
      updateRecord[_index].active = e.target.checked;
      setRecords({ ...records, data: updateRecord });
    }
    let _fd = {
      _method: "patch",
      status: e.target.checked ? "active" : "not-active",
    };
    try {
      dispatch(handleLoader(true));
      await ApiManager("post", `superAdmin/venues/${item?.venue_id}`, _fd);
      showMessage("success", "Record status updated successfully.");
    } catch (error) {
      showMessage("error", error?.response?.data?.error?.message);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const thLabels = ["ID", "Name", "Seating Capacity", "Status", "Actions"];

  return (
    <div>
      <Box>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          Venue
        </Typography>
        <Stack direction="row" m={2}>
          <Button
            onClick={() => navigate("/admin/venue/add")}
            startIcon={<AddIcon />}
          >
            Add Venue
          </Button>
          <ExportBtn
            path={`reports-admin-venues-csv?page=${page}&per_page=${rowsPerPage}&`}
            data={columns}
          />
        </Stack>
        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.per_page || 0}
              total={records?.total || 0}
            />
          }
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.data?.length || false}
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {records?.data?.length &&
            records?.data.map((item, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell>{item?.venue_id}</StyledTableCell>
                <StyledTableCell>{item?.name}</StyledTableCell>
                <StyledTableCell>{item?.seating_capacity}</StyledTableCell>
                <StyledTableCell>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={item?.active}
                        onChange={(e) => handleStatus(e, item, i)}
                      />
                    }
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    <Tooltip title="Edit" placement="top">
                      <IconButton
                        color="info"
                        onClick={() =>
                          navigate(`/admin/venue/update/${item?.venue_id}`)
                        }
                      >
                        <CreateRoundedIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableWrapper>
      </Box>
    </div>
  );
};

export default SpVenue;
