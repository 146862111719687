import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TableCell,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { Edit, Replay, Visibility } from "@mui/icons-material";
import MailIcon from "@mui/icons-material/Mail";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import {
  ConfirmationModal,
  ExportBtn,
  SelectBox,
  StartAndEndDateFilter,
  StyledTableCell,
  StyledTableRow,
  TablePagination,
  TableWrapper,
} from "../../../../component";
import usePageTitle from "../../../../hooks/use-page-title";
import ApiManager from "../../../../services/api-manager";
import { handleLoader, setToast } from "../../../../store/reducer";
import Utils from "../../../../utils/utils";

const SpOrderDetails = () => {
  usePageTitle("Ticket Orders");
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [records, setRecords] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [modalState, setModalState] = useState(false);
  const [recordID, setRecordID] = useState(null);
  const initialFiltersState = {
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    status: "all",
    event_id: undefined,
  };

  const [filtersState, setFiltersState] = useState(initialFiltersState);

  const urlPerPage = searchParams.get("perPage");
  const urlPage = searchParams.get("page");

  // const [unRead, setUnRead] = useState(0);
  const [liveEvents, setLiveEvents] = useState([]);
  const [page, setPage] = useState(1);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  const getData = async () => {
    try {
      setIsLoading(true);

      // Initialize an array to hold query parameters
      const queryParams = [];

      // Add query parameters conditionally based on whether they are present
      if (page) queryParams.push(`page=${page}`);
      if (rowsPerPage) queryParams.push(`per_page=${rowsPerPage}`);
      if (filtersState.start_date)
        queryParams.push(`start_date=${filtersState.start_date}`);
      if (filtersState.end_date)
        queryParams.push(`end_date=${filtersState.end_date}`);
      if (filtersState.status !== "all")
        queryParams.push(`status=${filtersState.status}`);
      if (filtersState.event_id)
        queryParams.push(`event_id=${filtersState.event_id}`);

      // Join the query parameters with '&' and append to the URL
      const queryString = queryParams.length ? `?${queryParams.join("&")}` : "";

      // Make the API call
      let { data } = await ApiManager(
        "get",
        `superAdmin/get-ticket-orders${queryString}`,
      );

      setRecords(data.tickets);
      setColumns(data.columns);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (urlPerPage) setRowsPerPage(Number(urlPerPage));
    if (urlPage) setPage(Number(urlPage) || page);
  }, [urlPerPage, urlPage]);

  const onChangePage = (_, newPage) => {
    searchParams.set("page", newPage);
    setSearchParams(searchParams);
    setPage(newPage);
  };

  useEffect(() => {
    const getLiveEvents = async () => {
      try {
        setIsLoading(true);
        let { data } = await ApiManager("get", `superAdmin/get-live-events`);
        setLiveEvents(
          data.response.detail.map((each) => ({
            label: each.name,
            value: each.event_id,
          })),
        );
      } catch (error) {
        console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
      } finally {
        setIsLoading(false);
      }
    };
    getLiveEvents();
  }, []);

  useEffect(() => {
    getData();
  }, [rowsPerPage, page, filtersState]);

  const onChangeRowsPerPage = (event) => {
    searchParams.set("perPage", parseInt(event.target.value, 10));
    setSearchParams(searchParams);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openModal = (_id) => {
    setModalState(true);
    setRecordID(_id);
  };

  const closeModal = () => {
    setModalState(false);
    setRecordID(null);
  };

  const deleteRecord = async () => {
    try {
      dispatch(handleLoader(true));
      await ApiManager("delete", `superAdmin/blogs/${recordID}`);
      showMessage("success", "Blog Deleted successfully.");
      closeModal();
      setRecords((prev) => ({
        ...prev,
        data: prev?.data.filter((item) => item?.blog_id !== recordID),
      }));
    } catch (error) {
      showMessage("error", error?.response?.data?.error?.message);
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const thLabels = [
    // "Event Name",
    // "Customer Name",
    "Email",
    "Starting amount",
    "Payment processing fee",
    "Service fee",
    "Final amount",
    "Payment method",
    "Payment Refrence id",
    "Status",
    "Purchase Date & Time",
    "Actions",
  ];

  let exportQuery = [
    filtersState.start_date && `start_date=${filtersState.start_date}`,
    filtersState.end_date && `end_date=${filtersState.end_date}`,
    filtersState.status !== "all" && `status=${filtersState.status}`,
    filtersState.event_id && `event_id=${filtersState.event_id}`,
    `page=${page}`,
    `per_page=${rowsPerPage}&`,
  ]
    .filter(Boolean)
    .join("&");
  console.log("exportQuery: ", exportQuery);
  return (
    <div>
      <Box>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          Tickets orders
        </Typography>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12}>
            <ExportBtn
              path={`reports-ticket-orders-csv?${exportQuery}`}
              data={columns}
            />
          </Grid>
          <Grid item xl={6} lg={6} md={12} xs={12}>
            <StartAndEndDateFilter
              onFilterChange={(filterState) => {
                setPage(1);
                setFiltersState((prev) => ({ ...prev, ...filterState }));
              }}
              onRequestClear={() =>
                setFiltersState((prev) => ({
                  ...prev,
                  start_date: "",
                  end_date: "",
                  start_time: "",
                  end_time: "",
                }))
              }
            />
          </Grid>
          <Grid item xl={3} lg={3} md={12} xs={12}>
            <Box>
              <SelectBox
                fullWidth
                items={liveEvents}
                label="Live Events"
                name="event_id"
                isContent={liveEvents?.length}
                value={filtersState?.event_id}
                onChange={(e) =>
                  setFiltersState({ ...filtersState, event_id: e.target.value })
                }
              />
            </Box>
          </Grid>
          <Grid item xl={3} lg={3} md={12} xs={12}>
            <Stack direction={"row"} width={"100%"} spacing={1}>
              <Box width={1}>
                <SelectBox
                  fullWidth
                  items={[
                    { label: "All", value: "all" },
                    { label: "Pending", value: "pending" },
                    { label: "Completed", value: "completed" },
                  ]}
                  label="Status"
                  name="status"
                  isContent
                  value={filtersState?.status}
                  onChange={(e) =>
                    setFiltersState({ ...filtersState, status: e.target.value })
                  }
                />
              </Box>
              {filtersState?.event_id && (
                <Button
                  onClick={() => {
                    setFiltersState((pre) => ({
                      ...pre,
                      status: "all",
                      event_id: undefined,
                    }));
                  }}
                  variant="contained"
                  color="error"
                >
                  Clear
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.per_page || 0}
              total={records?.total || 0}
            />
          }
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.data?.length || false}
          thContent={
            <>
              <TableCell
                sx={{
                  position: "sticky",
                  left: 0,
                  bgcolor: "white",
                  outline: "1px solid #E7E7E7",
                }}
              >
                Event Name
              </TableCell>
              <TableCell
                sx={{
                  position: "sticky",
                  left: "153px",
                  bgcolor: "white",
                  outline: "1px solid #E7E7E7",
                }}
              >
                Customer Name
              </TableCell>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {records?.data?.length &&
            records?.data.map((item, i) => (
              <TableRows
                key={item.ticket_order_id}
                item={item}
                setRecords={setRecords}
                getData={getData}
              />
            ))}
        </TableWrapper>
      </Box>
      <ConfirmationModal
        callBack={deleteRecord}
        open={modalState}
        handleClose={closeModal}
      />
    </div>
  );
};
export default SpOrderDetails;

const TableRows = ({ item, setRecords, getData }) => {
  const [feesLoading, setFeesLoading] = useState(false);
  const [refundLoading, setRefundLoading] = useState(false);
  const [resendEmailLoading, setResendEmailLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const emailInputRef = useRef({
    current: {
      value: "",
    },
  });

  const dispatch = useDispatch();

  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  const handleToggle = (item) => {
    emailInputRef.current.value = item.email;
    setIsEdit(!isEdit);
  };

  const handleSemail = async (item) => {
    if (!emailInputRef.current.value.trim()) {
      return;
    }
    try {
      setFeesLoading(true);
      let { data } = await ApiManager(
        "post",
        `superAdmin/update-order-customer-email`,
        {
          email: emailInputRef.current.value,
          ticket_order_id: item.ticket_order_id,
        },
      );

      showMessage("success", "Email Updated");
      getData();
    } catch (error) {
      showMessage("error", error?.response?.data?.error?.message);

      console.log(error);
    } finally {
      setFeesLoading(false);
    }
  };

  const refundOrder = async (ticketId) => {
    setRefundLoading(ticketId);
    try {
      let { data } = await ApiManager(
        "post",
        `superAdmin/ticket-order-refund/${ticketId}`,
      );
      showMessage("success", data?.response?.message);
      getData();
    } catch (error) {
      console.log("🚀 ~ refundOrder ~ error:", error);
      setRefundLoading(null);
      showMessage("error", error?.response?.data?.error?.message);
    }
  };

  const resendEmail = async (ticketId) => {
    setResendEmailLoading(ticketId);
    try {
      let { data } = await ApiManager(
        "get",
        `superAdmin/resend-tickets-in-email/${ticketId}`,
      );
      showMessage("success", "Tickets resent successfully!");
      getData();
    } catch (error) {
      console.log("🚀 ~ refundOrder ~ error:", error);
      setResendEmailLoading(null);
      showMessage("error", error?.response?.data?.error?.message);
    }
  };

  const renderInput = useCallback(
    (item) => {
      return (
        <Stack direction="row">
          {isEdit && (
            <>
              <TextField
                key={item?.ticket_order_id}
                inputRef={emailInputRef}
                defaultValue={emailInputRef.current.value || item.email}
                type="email"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LoadingButton
                        loading={feesLoading}
                        onClick={() => handleSemail(item)}
                      >
                        Update
                      </LoadingButton>
                    </InputAdornment>
                  ),
                }}
              />
            </>
          )}
        </Stack>
      );
    },
    [isEdit, feesLoading],
  );

  return (
    <StyledTableRow>
      <StyledTableCell sticky>
        {item?.event_detail?.name
          ? Utils.limitText(item?.event_detail?.name, 20)
          : "-"}
      </StyledTableCell>
      <StyledTableCell sticky left={"153px"}>
        {item?.first_name + " " + item?.last_name}
      </StyledTableCell>
      {/* <StyledTableCell>{item?.email}</StyledTableCell> */}

      <StyledTableCell>
        {!isEdit && (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {item?.email}
            <IconButton color="info" onClick={() => handleToggle(item)}>
              <Edit />
            </IconButton>
          </Stack>
        )}
        {renderInput(item)}
      </StyledTableCell>
      <StyledTableCell>
        {item?.starting_amount} {item?.event_detail.currency?.currency_symbol}
      </StyledTableCell>
      <StyledTableCell>
        {item?.sponsor_pp_fee === "yes" ? (
          <Chip label="Sponsored" color="success" />
        ) : (
          +item?.pp_fees_amount +
          +item?.payment_method_fix_amount +
          item?.event_detail.currency?.currency_symbol
        )}
      </StyledTableCell>

      <StyledTableCell>
        {item?.sponsor_service_fee === "yes" ? (
          <Chip label="Sponsored" color="success" />
        ) : (
          item?.service_fees_amount +
          item?.event_detail.currency?.currency_symbol
        )}
      </StyledTableCell>
      <StyledTableCell>
        {item?.final_amount} {item?.event_detail.currency?.currency_symbol}
      </StyledTableCell>
      <StyledTableCell>
        {item?.platform === "website"
          ? Utils.capitalize(item.payment_method)
          : "Reseller App"}
      </StyledTableCell>
      <StyledTableCell>
        {item?.payment_method === "cybersource"
          ? JSON.parse(item?.capture_payment_response || "{}")?.id ?? "-"
          : item?.payment_method === "lpm"
          ? item?.ticket_order_id
          : item?.payment_method === "sentoo"
          ? JSON.parse(item?.sentoo_response_payload || "{}")?.success
              ?.message ?? "-"
          : ""}
      </StyledTableCell>

      <StyledTableCell>
        <Chip
          label={item?.status}
          color={
            item?.status === "completed"
              ? "success"
              : item?.status === "pending"
              ? "warning"
              : "error"
          }
          sx={{ textTransform: "capitalize" }}
        />
      </StyledTableCell>

      <StyledTableCell>
        {moment(item?.created_at).format(process.env.REACT_APP_DATETIME_FORMAT)}
      </StyledTableCell>

      <StyledTableCell>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Tooltip title="Details" placement="top">
            <Link
              to={`/admin/tickets/tickets-orders/details/${item?.ticket_order_id}`}
            >
              <IconButton color="info">
                <Visibility />
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip
            title="Resend Email"
            onClick={() => resendEmail(item?.ticket_order_id)}
            placement="top"
          >
            {resendEmailLoading === item?.ticket_order_id ? (
              <IconButton>
                <CircularProgress size={20} />
              </IconButton>
            ) : (
              <IconButton color="info">
                <MailIcon />
              </IconButton>
            )}
          </Tooltip>
          <Tooltip
            title="Refund"
            onClick={() => refundOrder(item?.ticket_order_id)}
            placement="top"
          >
            {refundLoading === item?.ticket_order_id ? (
              <IconButton>
                <CircularProgress size={20} />
              </IconButton>
            ) : (
              <IconButton color="info">
                <Replay />
              </IconButton>
            )}
          </Tooltip>
        </Stack>
      </StyledTableCell>
    </StyledTableRow>
  );
};
