import styled from "@emotion/styled";
import { TableCell, tableCellClasses } from "@mui/material";
import React from "react";

const StyledTableCell = ({ children, type, sticky, left, ...rest }) => {
  const StyledTableCellMy = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  return (
    <StyledTableCellMy
      component={type}
      scope="row"
      sx={
        sticky && {
          position: "sticky",
          left: left || 0,
          bgcolor: "white",
          outline: "1px solid #E7E7E7",
          zIndex: "1",
        }
      }
      {...rest}
    >
      {children}
    </StyledTableCellMy>
  );
};

export default StyledTableCell;
