import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Checkbox,
  Stack,
  Divider,
  TextField,
  Button,
  Autocomplete,
  FormControlLabel,
  Alert,
} from "@mui/material";
import { useTimezoneSelect, allTimezones } from "react-timezone-select";
import { useDispatch } from "react-redux";
import moment from "moment";
import { tz } from "moment-timezone";
import { useNavigate, useParams } from "react-router";
import dayjs from "dayjs";

import {
  FilePicker,
  InputDate,
  InputField,
  SelectBox,
} from "../../../component";
import { handleLoader, setToast } from "../../../store/reducer";
import errorsSetter from "../../../helpers/error-setter";
import ApiManager from "../../../services/api-manager";
import usePageTitle from "../../../hooks/use-page-title";
import { TimePicker } from "@mui/x-date-pickers";

const labelStyle = "original";
const dateFormat = "YYYY-MM-DD";
const timezones = { ...allTimezones };
const initialValue = {
  allowed_ticket_types: "single",
  vouchers_allowed: 0,
  allow_online_topups: 0,
  allow_refunds_at_the_end_of_event: 0,
  show_on_website: 0,
};

const CreateEvent = () => {
  const params = useParams();
  const eventID = params?.id;
  usePageTitle(eventID ? "Update Event" : "Create Event");
  const [currenciesData, setCurrenciesData] = useState({
    isLoading: true,
    message: "Loading currencies data from server...",
    data: [],
  });
  const [formData, setFormData] = useState({ ...initialValue });
  const [formErrors, setFormErrors] = useState({});
  const timeZones = tz.names();
  // const { options } = useTimezoneSelect({
  //   labelStyle,
  //   timezones,
  // });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    async function init() {
      let { data: _d } = await ApiManager("get", "organizer/currencies");
      setCurrenciesData({
        isLoading: false,
        message:
          _d?.currencies?.length === 0 ? "Currencies data not found" : "",
        data: _d?.currencies.map((item) => ({
          label: item.currency_name + ", " + item.currency_symbol,
          value: item?.currency_id,
        })),
      });
    }
    init();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    async function init() {
      if (eventID) {
        try {
          dispatch(handleLoader(true));
          let { data } = await ApiManager("get", `organizer/events/${eventID}`);
          setFormData({
            ...data,
            start_date: moment(data?.start_date),
            end_date: moment(data?.end_date),
            end_time: moment(
              moment().format("DD-MM-YYYY") + "" + data?.end_time,
              "DD-MM-YYYY hh:mm A",
            ),
            start_time: moment(
              moment().format("DD-MM-YYYY") + "" + data?.start_time,
              "DD-MM-YYYY hh:mm A",
            ),
            currency_id: data?.currency?.currency_id || "",
            allow_online_topups: data?.allow_online_topups ? 1 : 0,
            allow_refunds_at_the_end_of_event:
              data?.allow_refunds_at_the_end_of_event ? 1 : 0,
            vouchers_allowed: data?.vouchers_allowed ? 1 : 0,
            show_on_website: data?.show_on_website ? 1 : 0,
          });
        } catch (error) {
          showMessage("error", error?.response?.data?.error?.message);
        } finally {
          dispatch(handleLoader(false));
        }
      }
      // else {
      //   setFormData((prev) => ({
      //     ...prev,
      //     timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      //   }));
      // }
    }
    init();
  }, [eventID]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validate = () => {
    let errors = {};
    let flag = true;
    if (!!!formData?.image && !eventID) {
      errors.image = "File required.";
      flag = false;
    }
    if (formData?.start_date?.isAfter(formData?.end_date)) {
      flag = false;
      showMessage("warning", "Start date cannot be after the end date");
    }
    if (formData?.end_date?.isBefore(formData?.start_date)) {
      flag = false;
      showMessage("warning", "End date cannot be before the start date.");
    }

    setFormErrors((prev) => ({ ...prev, ...errors }));
    return flag;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        dispatch(handleLoader(true));
        setFormErrors({});
        let _url = eventID
          ? `organizer/update-event-by-admin-or-organizer/${eventID}`
          : "organizer/store-event-by-admin-or-organizer";
        let _Obj = {
          ...formData,
          start_date: moment(formData?.start_date).format(dateFormat),
          end_date: moment(formData?.end_date).format(dateFormat),
          start_time: moment(formData?.start_time).format("HH:mm"),
          end_time: moment(formData?.end_time).format("HH:mm"),
        };
        // if (eventID) {
        //   _Obj._method = "patch";
        // }
        if (!!_Obj?.image && typeof _Obj?.image !== "object") {
          delete _Obj.image;
        }
        let _fd = new FormData();
        for (const key in _Obj) {
          if (Object.hasOwnProperty.call(_Obj, key)) {
            _fd.append(key, _Obj[key]);
          }
        }
        await ApiManager("post", _url, _fd, {
          "Content-Type": "multipart/form-data",
        });
        if (eventID) {
          showMessage("success", "Event updated successfully.");
        } else {
          showMessage("success", "Event created successfully.");
        }
        navigate("/events");
      } catch (error) {
        if (error?.response?.status === 422) {
          return setFormErrors(errorsSetter(error));
        }
        if (error?.response?.status === 400) {
          showMessage("error", error?.response?.data);
        } else {
          showMessage("error", error?.response?.data?.error?.message);
        }
      } finally {
        dispatch(handleLoader(false));
      }
    }
  };

  return (
    <Box m={{ sm: 2 }}>
      <Box component={Paper} elevation={6} p={2}>
        <Box
          component="form"
          autoComplete="off"
          autoCapitalize="off"
          onSubmit={handleSubmit}
        >
          <Typography variant="h5">
            {!!eventID ? "Update" : "Create"} Event
          </Typography>
          <Typography variant="body1">
            Create an Event to start using
          </Typography>

          <Grid container columnSpacing={3} mt={2} rowSpacing={2}>
            <Grid item xs={12}>
              <FilePicker
                onChange={(e) => {
                  const file = e.target.files[0];
                  setFormData((prev) => ({
                    ...prev,
                    image: file,
                    image_url: URL.createObjectURL(file),
                  }));
                }}
                labelTop="Event Logo"
                error={formErrors?.image}
                imageUrl={formData?.image_url}
                helperText="Recommended file dimension[WxH] : 100x56"
                title="Pick a file"
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <Box>
                <InputField
                  label="Name"
                  size="small"
                  name="name"
                  required
                  error={formErrors?.name}
                  value={formData?.name}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>

            <Grid item sm={4} xs={12}>
              <SelectBox
                fullWidth
                items={[
                  { label: "Yes", value: 1 },
                  { label: "No", value: 0 },
                ]}
                label="Allow online topups"
                name="allow_online_topups"
                required
                error={formErrors?.allow_online_topups}
                value={formData?.allow_online_topups ? 1 : 0}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item sm={4} xs={12}>
              <SelectBox
                fullWidth
                items={[
                  { label: "Single", value: "single" },
                  { label: "Multiple", value: "multiple" },
                ]}
                label="Allowed Ticket Types"
                name="allowed_ticket_types"
                required
                error={formErrors?.allowed_ticket_types}
                value={formData?.allowed_ticket_types}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={3} mt={2} rowSpacing={2}>
            <Grid item sm={4} xs={12}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={timeZones}
                fullWidth
                size="small"
                value={formData?.timezone}
                key={formData?.timezone}
                renderInput={(params) => (
                  <TextField {...params} label="Timezone" />
                )}
                onChange={(e, val) =>
                  setFormData((prev) => ({ ...prev, timezone: val }))
                }
              />
            </Grid>

            <Grid item sm={4} xs={12}>
              <SelectBox
                fullWidth
                items={[
                  { label: "Yes", value: 1 },
                  { label: "No", value: 0 },
                ]}
                label="Allow refund at the end of event"
                name="allow_refunds_at_the_end_of_event"
                required
                error={formErrors?.allow_refunds_at_the_end_of_event}
                value={formData?.allow_refunds_at_the_end_of_event ? 1 : 0}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <SelectBox
                fullWidth
                items={[
                  { label: "Yes", value: 1 },
                  { label: "No", value: 0 },
                ]}
                label="Vouchers allowed"
                name="vouchers_allowed"
                required
                error={formErrors?.vouchers_allowed}
                value={formData?.vouchers_allowed ? 1 : 0}
                onChange={handleInputChange}
                helperText={
                  formData?.vouchers_allowed
                    ? "This option is functional when the device is connected to the internet."
                    : ""
                }
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <SelectBox
                fullWidth
                items={[
                  { label: "Yes", value: 1 },
                  { label: "No", value: 0 },
                ]}
                label="Scan Qr Code Only"
                name="scan_qrcode_only"
                required
                error={formErrors?.scan_qrcode_only}
                value={formData?.scan_qrcode_only ? 1 : 0}
                onChange={handleInputChange}
                isContent
              />
            </Grid>

            <Grid item xs={12}>
              <InputField
                label="Summary"
                size="small"
                name="summary"
                required
                error={formErrors?.summary}
                value={formData?.summary}
                onChange={handleInputChange}
                multiline
                rows={3}
              />
            </Grid>
          </Grid>
          <Stack direction={"column"} mt={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(formData?.show_on_website)}
                  value={Boolean(formData?.show_on_website)}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      show_on_website: e.target.checked ? 1 : 0,
                    }))
                  }
                  name="show_on_website"
                />
              }
              label="Show on website"
            />
            {Boolean(formData?.show_on_website) && (
              <Alert severity="warning" variant="filled">
                {/* If you are checking this, remember that this event will gonna
                show on the CX Events website and most of the data entry
                couldn't been done from this panel so it is for sure that the
                website will get break if you publish this event on website! */}
                If you are enabling this, remember that this event will show on
                the CX Events ticketing website and most of the data entry
                hasn’t been done from this panel so the website will break if
                you publish this event on website. Careful!!
              </Alert>
            )}
          </Stack>

          <Divider
            sx={{
              my: 3,
            }}
          />
          <Box my={2}>
            <Typography variant="h5">Event Currency </Typography>

            <Grid container columnSpacing={3} mt={0.2} rowSpacing={2}>
              <Grid item sm={4} xs={12}>
                {currenciesData?.isLoading ? (
                  <Typography variant="span">
                    {currenciesData?.message}
                  </Typography>
                ) : (
                  <SelectBox
                    fullWidth
                    items={currenciesData?.data}
                    label="Currency"
                    name="currency_id"
                    required
                    error={formErrors?.currency_id}
                    value={formData?.currency_id}
                    onChange={handleInputChange}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
          <Divider
            sx={{
              my: 3,
            }}
          />

          <Box my={2}>
            <Typography variant="h5">Event Time Settings </Typography>

            <Grid container columnSpacing={3} mt={0.2} rowSpacing={2}>
              <Grid item sm={3} xs={12}>
                <InputDate
                  label={"Start Date"}
                  error={formErrors?.start_date}
                  value={moment(formData?.start_date)}
                  onChange={(val) =>
                    setFormData((prev) => ({ ...prev, start_date: val }))
                  }
                  required={true}
                  maxDate={
                    formData?.end_date ? moment(formData?.end_date) : null
                  }
                />
              </Grid>

              <Grid item sm={3} xs={12}>
                <TimePicker
                  label={"Start time"}
                  error={formErrors?.start_time}
                  value={
                    formData?.start_time ? moment(formData?.start_time) : null
                  }
                  onChange={(val) =>
                    setFormData((prev) => ({ ...prev, start_time: val }))
                  }
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                      error: Boolean(formErrors?.start_time),
                      helperText: formErrors?.start_time,
                      required: true,
                    },
                  }}
                  maxTime={formData?.end_time}
                />
              </Grid>

              <Grid item sm={3} xs={12}>
                <InputDate
                  label={"End Date"}
                  error={formErrors?.end_date}
                  value={moment(formData?.end_date)}
                  onChange={(val) =>
                    setFormData((prev) => ({ ...prev, end_date: val }))
                  }
                  required={true}
                  minDate={
                    formData?.start_date ? moment(formData?.start_date) : null
                  }
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <TimePicker
                  labelTop="End time"
                  label={"End time"}
                  error={formErrors?.end_time}
                  value={formData?.end_time ? moment(formData?.end_time) : null}
                  onChange={(val) =>
                    setFormData((prev) => ({ ...prev, end_time: val }))
                  }
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true,
                      error: Boolean(formErrors?.end_time),
                      helperText: formErrors?.end_time,
                      required: true,
                    },
                  }}
                  minTime={formData?.start_time}
                />
              </Grid>
            </Grid>
          </Box>
          <Divider
            sx={{
              my: 1,
            }}
          />

          <Stack direction="row" gap={2} mt={2}>
            <Button
              variant="contained"
              type="submit"
              sx={{
                color: "white",
              }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => navigate("/events")}
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateEvent;
