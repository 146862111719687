import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  TableCell,
  Grid,
  IconButton,
} from "@mui/material";
import { useParams } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useSelector } from "react-redux";

import {
  ExportBtn,
  InputField,
  SearchInput,
  StartAndEndDateFilter,
  StyledTableCell,
  StyledTableRow,
  TablePagination,
  TableWrapper,
} from "../../../../component";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";
import RefreshIcon from "@mui/icons-material/Refresh";
import Utils from "../../../../utils/utils";
import moment from "moment";
import { Clear } from "@mui/icons-material";

const AcpReports = () => {
  usePageTitle("Access Control Points Report");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [searchCalled, setSearchCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [search, setSearch] = useState("");
  const [filtersState, setFiltersState] = useState({
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    search: "",
  });

  const { id } = useParams();
  const { user } = useSelector((state) => state.storeReducer);
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    getData();
  }, [
    page,
    rowsPerPage,
    id,
    filtersState.start_date,
    filtersState.end_date,
    filtersState.start_time,
    filtersState.end_time,
    searchCalled,
  ]);

  const getData = async () => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `${_role}/get-acp-report/${id}?page=${page}&per_page=${rowsPerPage}&start_date=${filtersState.start_date}&end_date=${filtersState.end_date}&search=${filtersState.search}&start_time=${filtersState?.start_time}&end_time=${filtersState?.end_time}`,
      );
      setRecords(data.response.detail.acps);
      setColumns(data.response.detail.columns);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div>
      <Box>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          Access Control Points Report
        </Typography>

        <Grid container spacing={2} mb={2}>
          <Grid item xl={2} lg={3} md={12} xs={12}>
            <Stack direction="row">
              <Button startIcon={<RefreshIcon />} onClick={() => getData()}>
                Refresh
              </Button>
              {/* <ExportBtn
                data={columns}
                path={`reports-event-tickets-csv?event_id=${id}&start_date=${filtersState.start_date}&end_date=${filtersState.end_date}&search=${filtersState.search}&start_time=${filtersState?.start_time}&end_time=${filtersState?.end_time}&page=${page}&per_page=${rowsPerPage}&`}
              /> */}
            </Stack>
          </Grid>
          {/* <Grid item xl={3.5} lg={4} md={12} xs={12}>
            <SearchInput
              value={filtersState.search}
              onChange={(e) =>
                setFiltersState((prev) => ({ ...prev, search: e.target.value }))
              }
              onRequestSearch={() => {
                if (page === 1) {
                  setSearchCalled(!searchCalled);
                } else {
                  setPage(1);
                }
              }}
              onRequestClear={() => {
                setFiltersState({ ...filtersState, search: "" });
                if (page === 1) {
                  setSearchCalled(!searchCalled);
                } else {
                  setPage(1);
                }
              }}
            />
          </Grid>
          <Grid item xl={6.5} lg={5} md={12} xs={12}>
            <StartAndEndDateFilter
              onFilterChange={(filterState) => {
                setPage(1);
                setFiltersState((prev) => ({ ...prev, ...filterState }));
              }}
              onRequestClear={() =>
                setFiltersState((prev) => ({
                  ...prev,
                  start_date: "",
                  end_date: "",
                  start_time: "",
                  end_time: "",
                }))
              }
            />
          </Grid> */}
        </Grid>

        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.per_page || 0}
              total={records?.total || 0}
            />
          }
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.data?.length || false}
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
        >
          {records?.data?.map((item, i) => (
            <StyledTableRow key={i}>
              <StyledTableCell>{item?.acp_name || "-"}</StyledTableCell>
              <StyledTableCell>{item?.action === 'pass_through' ? "Pass Through" : "Multiple Times"}</StyledTableCell>
              <StyledTableCell>{item?.ticket_type_name || "-"}</StyledTableCell>
              <StyledTableCell>{item?.open_id || "-"}</StyledTableCell>
              <StyledTableCell>
                {moment(item?.created_at).format(
                  process.env.REACT_APP_DATE_FORMAT,
                )}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableWrapper>
      </Box>
    </div>
  );
};

const thLabels = [
  "Acp Name",
  "Action",
  "Ticket Type Name",
  "Code",
  "Created At",
];

export default AcpReports;
