import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  TableCell,
  Tooltip,
  IconButton,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { useDispatch, useSelector } from "react-redux";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import { Link, useNavigate, useParams } from "react-router-dom";
import {
  ExportBtn,
  InputField,
  StyledTableCell,
  StyledTableRow,
  TablePagination,
  TableWrapper,
} from "../../../../component";
import usePageTitle from "../../../../hooks/use-page-title";
import ApiManager from "../../../../services/api-manager";
import QrCodeModal from "./qr-code-modal";
import { handleLoader, setToast } from "../../../../store/reducer";

const EventTickets = () => {
  usePageTitle("Tickets");
  const { id } = useParams();
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [qrCodeObj, setOrCodeObj] = useState(null);
  const [search, setSearch] = useState("");
  const [searchCalled, setSearchCalled] = useState(false);

  const [columns, setColumns] = useState([]);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.storeReducer);
  const _isAdmin = user?.role === "super_admin" ? true : false;
  const _role = user?.role === "super_admin" ? "superAdmin" : "organizer";

  const getData = async (_search = "") => {
    try {
      setIsLoading(true);
      let { data } = await ApiManager(
        "get",
        `${_role}/tickets?event_id=${id}&page=${page}&per_page=${rowsPerPage}&search=${_search}`,
      );
      setRecords(data?.ticket);
      setColumns(data?.columns);
    } catch (error) {
      console.log("🚀 ~ file: events.js:47 ~ getData ~ error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [page, rowsPerPage, id]);

  const onChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const onChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCsvUpload = async (event) => {
    // event.currentTarget.value = null
    if (!event.target.files.length) {
      return;
    }
    console.log(
      "🚀 ~ file: event-tickets.js:60 ~ handleCsvUpload ~ event.target.files:",
      event.target.files,
    );
    let ext = event.target.files[0].name.split(".");
    if (ext[1] !== "csv") {
      return dispatch(
        setToast({
          type: "error",
          message: "Please select only .csv extension file.",
        }),
      );
    }

    try {
      dispatch(handleLoader(true));
      let _fd = new FormData();
      _fd.append("file", event.target.files[0]);
      let { data } = await ApiManager(
        "post",
        `organizer/tickets/upload-csv?event_id=${id}`,
        _fd,
        { "Content-Type": "multipart/form-data" },
      );
      dispatch(setToast({ type: "success", message: data }));
      if (page === 1) {
        getData();
      } else {
        setPage(1);
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: event-tickets.js:85 ~ handleCsvUpload ~ error:",
        error,
      );
      dispatch(
        setToast({
          type: "error",
          message: error?.response?.data?.error?.message,
        }),
      );
    } finally {
      dispatch(handleLoader(false));
    }
  };

  const renderRow = (item) => (
    <StyledTableRow>
      <StyledTableCell>{item?.ticket_id || "-"}</StyledTableCell>
      <StyledTableCell>{item?.ticket_type?.name || "-"}</StyledTableCell>
      <StyledTableCell>{item?.qrcode || "-"}</StyledTableCell>
      <StyledTableCell>{item?.name || "-"}</StyledTableCell>
      <StyledTableCell>{item?.email || "-"}</StyledTableCell>
      <StyledTableCell>
        <Stack direction="row" spacing={0.5} alignItems="center">
          {!_isAdmin && (
            <Tooltip title="Edit" placement="top">
              <IconButton
                color="info"
                onClick={() =>
                  navigate(
                    `/event/${id}/management/access-control/tickets/edit/${item?.ticket_id}`,
                  )
                }
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="info" placement="top" color="info">
            <IconButton
              type="button"
              onClick={() =>
                navigate(
                  `/event/${id}/management/access-control/tickets/ticket-info/${item?.ticket_id}`,
                )
              }
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Qr Code" placement="top" color="info">
            <IconButton type="button" onClick={() => openModal(item)}>
              <QrCodeIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </StyledTableCell>
    </StyledTableRow>
  );

  const openModal = (item) => {
    setOrCodeObj(item?.qrcode);
    setShowModal(true);
  };

  const thLabels = [
    "Ticket ID",
    "Ticket Type",
    "Qr Code",
    "Name On Ticket",
    "Email",
    "Manage",
  ];

  return (
    <div>
      <Box>
        <Typography variant="h4" color="initial" fontWeight={"bold"} m={2}>
          Tickets
        </Typography>
        <Stack
          direction={{ sm: "row", xs: "column" }}
          justifyContent={{ sm: "space-between", xs: "center" }}
          my={2}
        >
          <Stack
            direction={{ sm: "row", xs: "column" }}
            justifyContent={{ sm: "start", xs: "center" }}
          >
            <Button
              startIcon={<UploadIcon />}
              component={"label"}
              htmlFor="myFileInput"
            >
              File Upload
            </Button>
            <input
              type="file"
              id="myFileInput"
              accept=".csv"
              style={{
                display: "none",
              }}
              onClick={(event) => {
                event.target.value = null;
              }}
              onChange={handleCsvUpload}
            />
            {!_isAdmin && (
              <Button
                startIcon={<ControlPointIcon />}
                onClick={() =>
                  navigate(
                    `/event/${id}/management/access-control/tickets/create`,
                  )
                }
              >
                Add Ticket
              </Button>
            )}

            <a
              href={`${process.env.REACT_APP_SAMPLE_API_KEY}sample-tickets-file-download`}
              target="_blank"
              rel="noreferrer"
            >
              <Button startIcon={<FileDownloadIcon />}>
                Sample File Download
              </Button>
            </a>

            <ExportBtn
              path={`reports-event-tickets-csv?event_id=${id}&page=${page}&per_page=${rowsPerPage}&`}
              data={columns}
            />
          </Stack>

          <Stack direction="row" gap={2} alignItems={"center"}>
            <InputField
              label="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Name/Email"
            />
            {!!search && (
              <Button
                onClick={() => {
                  if (page === 1) {
                    getData(search);
                  } else {
                    setPage(1);
                  }
                  setSearchCalled(true);
                }}
                variant="contained"
                sx={{ color: "white" }}
              >
                Search
              </Button>
            )}
            {searchCalled && (
              <Button
                onClick={() => {
                  setSearch("");
                  if (page === 1) {
                    getData();
                  } else {
                    setPage(1);
                  }
                  setSearchCalled(false);
                }}
                variant="contained"
                color="error"
              >
                Clear
              </Button>
            )}
          </Stack>
        </Stack>

        <TableWrapper
          pagination={
            <TablePagination
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              page={page}
              rowsPerPage={rowsPerPage}
              perPage={records?.per_page || 0}
              total={records?.total || 0}
            />
          }
          thContent={
            <>
              {thLabels.map((v) => (
                <TableCell key={v}>{v}</TableCell>
              ))}
            </>
          }
          spanTd={thLabels.length}
          isLoading={isLoading}
          isContent={records?.data?.length || false}
        >
          {records?.data.map((item, i) => (
            <Fragment children={renderRow(item)} key={i} />
          ))}
        </TableWrapper>
        <QrCodeModal
          open={showModal}
          close={() => setShowModal(false)}
          ticketObj={qrCodeObj}
          title="Ticket QRCode"
        />
      </Box>
    </div>
  );
};

export default EventTickets;
