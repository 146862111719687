import React, { useEffect } from "react";
import {
  Box,
  Paper,
  Fab,
  Typography,
  Grid,
  Divider,
  Chip,
  TableCell,
} from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { handleLoader, setToast } from "../../../../store/reducer";
import ApiManager from "../../../../services/api-manager";
import usePageTitle from "../../../../hooks/use-page-title";
import moment from "moment";
import {
  StyledTableCell,
  StyledTableRow,
  TableWrapper,
} from "../../../../component";

const SpOrderAllDetails = () => {
  usePageTitle("View Email");
  const { orderId } = useParams();
  const [record, setRecord] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showMessage = (type, msg) =>
    dispatch(setToast({ type: type, message: msg }));

  useEffect(() => {
    async function init() {
      if (orderId) {
        try {
          dispatch(handleLoader(true));
          let { data } = await ApiManager(
            "get",
            `superAdmin/get-ticket-orders/${orderId}`,
          );
          setRecord(data);
        } catch (error) {
          showMessage("error", error?.response?.data?.error?.message);
        } finally {
          dispatch(handleLoader(false));
          setIsLoading(false);
        }
      }
    }
    init();
  }, [orderId]);

  return (
    <Box m={{ sm: 2 }}>
      <Box
        component={Paper}
        elevation={6}
        sx={{
          p: 2,
        }}
      >
        <Fab onClick={() => navigate(-1)} size="small">
          <ArrowBackIcon />
        </Fab>
        <br />
        <br />
        <Box>
          <Grid container spacing={3}>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>Event name</Typography>
              <Typography sx={styles.text}>
                {record?.event_detail?.name || "[name]"}
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>User name</Typography>
              <Typography sx={styles.text}>
                {record?.customer_detail?.first_name || "[First name]"}{" "}
                {record?.customer_detail?.last_name || "[Last name]"}
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>First name</Typography>
              <Typography sx={styles.text}>
                {record?.customer_detail?.first_name || "[First name]"}
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>Last name</Typography>
              <Typography sx={styles.text}>
                {record?.customer_detail?.last_name || "[Last name]"}
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12} zeroMinWidth>
              <Typography sx={styles.hd} noWrap>
                Email
              </Typography>
              <Typography sx={styles.text} noWrap>
                {record?.customer_detail?.email || "[email]"}
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>Phone number</Typography>
              <Typography sx={styles.text}>
                {record?.phone || "[Phone number]"}
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>Starting Amount</Typography>
              <Typography sx={styles.text}>
                {record?.starting_amount +
                  " " +
                  record?.event_detail?.currency?.currency_symbol ||
                  "[Starting amount]"}
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>Promotion code applied</Typography>
              <Typography sx={styles.text}>
                {record?.promotion_code ? "Yes" : "No" || "[Promotion code]"}
              </Typography>
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>Discounted percentage</Typography>
              <Typography sx={styles.text}>
                {record?.discount_percentage}%
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>Discounted amount</Typography>
              <Typography sx={styles.text}>
                {" "}
                {record?.discounted_amount +
                  " " +
                  record?.event_detail?.currency?.currency_symbol}
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>
                Payment processing fees share
              </Typography>
              <Typography sx={styles.text}>{record?.pp_fees_share}%</Typography>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>
                Payment processing fees amount
              </Typography>
              <Typography sx={styles.text}>
                {record?.sponsor_pp_fee === "yes" ? (
                  <Chip label="Sponsored" color="success" />
                ) : (
                  +record?.pp_fees_amount +
                  +record?.payment_method_fix_amount +
                  record?.event_detail.currency?.currency_symbol
                )}
              </Typography>
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>Service fees share</Typography>
              <Typography sx={styles.text}>
                {record?.service_fees_share}%
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>Service fees amount</Typography>
              {/* <Typography sx={styles.text}>{record?.service_fees_amount + " " + record?.event_detail?.currency?.currency_symbol}</Typography> */}
              <Typography sx={styles.text}>
                {record?.sponsor_service_fee === "yes" ? (
                  <Chip label="Sponsored" color="success" />
                ) : (
                  record?.service_fees_amount +
                  record?.event_detail.currency?.currency_symbol
                )}
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>Final amount</Typography>
              <Typography sx={styles.text}>
                {record?.final_amount +
                  " " +
                  record?.event_detail?.currency?.currency_symbol}
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>Payment method</Typography>
              <Typography sx={styles.text}>
                {" "}
                {record?.payment_method}
              </Typography>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>Platform</Typography>
              <Typography sx={styles.text}> {record?.platform}</Typography>
            </Grid>

            <Grid item lg={3} md={4} sm={6} xs={12}>
              <Typography sx={styles.hd}>Status</Typography>
              {record?.status && (
                <Chip
                  label={record?.status}
                  color={
                    record?.status === "completed"
                      ? "success"
                      : record?.status === "pending"
                      ? "warning"
                      : "error"
                  }
                  sx={{ textTransform: "capitalize" }}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5" fontWeight="bold">
                Ticket details
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TableWrapper
                spanTd={thLabels.length}
                isLoading={isLoading}
                isContent={record?.ticket_order_details?.length || false}
                thContent={
                  <>
                    {thLabels.map((v) => (
                      <TableCell key={v}>{v}</TableCell>
                    ))}
                  </>
                }
              >
                {record?.ticket_order_details?.length &&
                  record?.ticket_order_details?.map((item, i) => (
                    <StyledTableRow key={i}>
                      <StyledTableCell>
                        {item?.ticket_type_details?.name}
                      </StyledTableCell>
                      <StyledTableCell>{item?.quantity}</StyledTableCell>
                      <StyledTableCell>
                        {item?.individual_amount}{" "}
                        {record?.event_detail?.currency?.currency_symbol}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableWrapper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default SpOrderAllDetails;

const styles = {
  hd: {
    fontSize: 18,
    fontWeight: "bold",
    mb: 0.5,
  },
  text: {
    fontSize: 16,
    mb: 2,
    textTransform: "capitalize",
  },
};

const thLabels = ["Name", "Quantity", "Individual amount"];
